@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_font.scss';
@import './jwplayer.scss';

.gradient-head {
  background: linear-gradient(105.33deg, #13273d 10.76%, #29adce 89.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gradient-head-large {
  background: linear-gradient(105.33deg, #ffffff 10.76%, #29adce 89.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.border-gradient-sm {
  border-bottom: 5px solid;
  border-top: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right,
      #13273d 10.76%,
      #29adce 74.24%);
}

.border-gradient-l {
  border-left: 4px solid;
  border-image-slice: 1;
  border-image:
    linear-gradient(to top,
      #13273d,
      #29adce) 1 100%;
}

.border-gradient-r {
  border-right: 4px solid;
  border-image-slice: 1;
  border-image:
    linear-gradient(to bottom,
      #13273d,
      #29adce) 1 100%;
}

.bg-gradient-head-large {
  background: linear-gradient(105.33deg, #ffffff 10.76%, #29adce 89.24%);
}

.scrollbar-none {
  &::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.btnMedium {
  @apply py-[0.625rem] px-4 rounded-[0.375rem];
}

.btnGradient {
  @apply text-white text-p3Semibold px-6 py-2 rounded-lg flex justify-center items-center gap-2;

  background: var(--Button, linear-gradient(105deg, #29ADCE 10.76%, #E84143 89.24%));
  &:hover {
    background: linear-gradient(105deg, #34d6ff 10.76%, #ff484b 89.24%); /* Slightly darker gradient */
  }
}

.bgLightBlueGradient {
  @apply absolute bg-lightBlue-200 top-0 bottom-0;

  left: -100vw;
  width: 200vw;

  background: linear-gradient(180deg,
      rgba(19, 39, 61, 0) 0%,
      rgba(29, 77, 131, 0.56) 50%,
      rgba(19, 39, 61, 0) 100%);
  opacity: 0.7;
}

.Container {
  @apply px-4 md:px-6 lg:px-6 xl:px-[8.875rem] xxl:px-[8.875rem] md:container md:mx-auto;
}

.bgGradientForContractExample {
  @apply bg-darkBlue-900;

  @media (min-width: 768px) {
    background: linear-gradient(180deg, #13273D 50.48%, #193f69 100%);
  }
}

.bgForSiteRefSection {
  @apply bg-darkBlue-900;

  @media (min-width: 768px) {
    background: #193f69;
  }
}

.flex-column {
  @apply flex flex-col;
}

.p-text {
  @apply text-p2Regular lg:text-p1Regular;
}

.blur-background {
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -20px;
    width: 110%;
    height: 100%;
    background: linear-gradient(270deg, #DFF3F8 0%, white 16%, #D4EFF5 100%);
    filter: blur(12px);
  }
}
