.jwplayer {
  @apply rounded-md;

  &.jw-flag-fullscreen {
    @apply rounded-none;
  }

  .jw-media {
    cursor: pointer !important;
  }
}